export const messagesGenerator = (type, alert) => {
  switch (type) {
    case 'warning':
      if (alert === 'success') return 'Warning has been sent.'
    case 'warningUser':
      if (alert === 'success') return 'Chat is activated.'
    case 'ban':
      if (alert === 'success') return 'User has been banned.'
    case 'complaints':
      if (alert === 'success') return 'Your complaint has been reported.'
    case 'options':
      if (alert === 'success') return 'Saved successfully.'
    case 'userBan':
      if (alert === 'success')
        return 'Your request has been forwarded to us. It will be answered as soon as possible after review.'
    case 'block':
      if (alert === 'success') return 'User has been blocked.'
    case 'unblock':
      if (alert === 'success') return 'User block has been removed.'
    case 'removeMessage':
      if (alert === 'success') return 'User message has been removed.'
    default:
      return 'There is a problem occurred. Error reported.'
  }
}

import React, { useState, useEffect, useRef } from 'react'
const LanguageSelector = ({ language, setLanguage }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(language)

  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null) // Ref for the dropdown

  const languages = [
    { name: 'English', flag: '🇺🇸', value: 'en' },
    { name: 'Français', flag: '🇫🇷', value: 'fr' },
    { name: 'Italiano', flag: '🇮🇹', value: 'it' },
    { name: 'Türkçe', flag: '🇹🇷', value: 'tr' },
    { name: '中文', flag: '🇨🇳', value: 'zh' },
    { name: 'Español', flag: '🇪🇸', value: 'es' },
    { name: 'Deutsch', flag: '🇩🇪', value: 'de' },
  ]

  const toggleDropdown = () => setIsOpen(!isOpen)

  // Close dropdown if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  useEffect(() => {
    setSelectedLanguage(language)
  }, [language])

  const getFlagByValue = (value) => {
    const language = languages.find((lang) => lang.value === value)
    return language
      ? `${language.flag} ${language.value.toUpperCase()}`
      : 'Flag not found'
  }

  // Function to handle language selection
  const selectLanguage = (event, language) => {
    event?.preventDefault()
    setSelectedLanguage(`${language.flag}`)
    setLanguage(language.value)
    setIsOpen(false)
  }

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md shadow-sm px-4 py-2 hover:bg-[#fff]/10
          text-sm font-medium text-gray-70 focus:outline-none"
          onClick={toggleDropdown}
        >
          {getFlagByValue(selectedLanguage)}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            {languages.map((language, index) => (
              <a
                key={index}
                href="#"
                className="text-black block px-4 py-2 text-sm"
                role="menuitem"
                tabIndex="-1"
                onClick={(e) => selectLanguage(e, language)}
              >
                {language.flag} {language.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default LanguageSelector

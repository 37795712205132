import dynamic from 'next/dynamic'
import { useState } from 'react'
import ColorChoiceToggle from './colorChoiceToggle'
import { nameColors, textColors } from '../config'
import { XIcon } from '@heroicons/react/solid'

const Model = dynamic(() => import('@/components/model'), {
  ssr: false,
})

export default function ColorsModal({
  onSave,
  nameColor,
  textColor,
  isOpen,
  setIsOpen,
}) {
  const [toggleNameColor, setToggleNameColor] = useState(nameColor)
  const [toggleTextColor, setToggleTextColor] = useState(textColor)
  const onClose = () =>
    setIsOpen((state) => {
      return { ...state, colorsModal: false }
    })
  const onHandle = () => {
    onSave({ nameColor: toggleNameColor, textColor: toggleTextColor })
  }

  return (
    <Model
      onClose={onClose}
      isOpen={isOpen}
      modelBG="bg-black/90"
      className="px-6 overflow-auto mt-[-5vh]"
    >
      <div className="w-full p-4 flex flex-col justify-start">
        <h2 className="text-2xl mt-0 text-center ">Settings</h2>
        <h3 className="text-lg mt-3 text-left ml-4">Name Color</h3>
        <ColorChoiceToggle
          choices={nameColors}
          initialChoice={toggleNameColor}
          onToggle={setToggleNameColor}
        />

        <h3 className="text-lg mt-3 text-left ml-4">Text Color</h3>
        <ColorChoiceToggle
          choices={textColors}
          initialChoice={toggleTextColor}
          onToggle={setToggleTextColor}
        />
      </div>
      <div
        className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
        onClick={onClose}
      >
        <XIcon className="w-5 h-5 text-gray-300" />
      </div>
      <div className="flex justify-end items-center px-4">
        <button
          onClick={onHandle}
          className="flex bg-cs-green px-5 py-1 rounded-md"
        >
          Save
        </button>
      </div>
    </Model>
  )
}

import HeaderButton from '@/components/button/HeaderButton'
import { getSportByName } from '@/constants/countries-leagues'
import useSidebar from '@/hooks/useSidebar'
import {
  Awards,
  BallPlinko,
  BetSlipIcon,
  Casino,
  DoubleCard,
  F1,
  GamesHeader,
  Golf,
  LilbitLogo,
  Mlb,
  Nba,
  Nhl,
  PlayingCardClover,
  SingleTicket,
  Sports,
} from '@/icons/index'
import authSelector from '@/redux/selectors/auth'
import betSlipsSelector from '@/redux/selectors/betSlips'
import { toggleBetslips, toggleBetSlipsMobile } from '@/redux/slices/betSlips'
import { setBottomTabLabel } from '@/redux/slices/sidebar'
import { contentStyle, contentTransitionStyles } from '@/utils/transition'
import { Popover, Transition } from '@headlessui/react'
import {
  ClockIcon,
  MenuAlt2Icon,
  StarIcon,
  VideoCameraIcon,
  XIcon,
} from '@heroicons/react/outline'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { Fragment, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Transition as ReactTransaction } from 'react-transition-group'
import { SecondaryIconButton } from '../sportsidebar'

const menus = [
  {
    label: 'Casino',
    icon: <Casino className="w-7 h-7 shrink-0" />,
    href: '',
    page: 'casino',

    games: [
      {
        gameName: 'Plinko',
        href: '/casino/plinko',
        icon: <BallPlinko className="w-7 h-7 shrink-0" />,
      },
      {
        gameName: 'Video Poker',
        href: '/casino/video-poker',
        icon: <PlayingCardClover className="w-7 h-6 shrink-0" />,
      },
      {
        gameName: 'Blackjack',
        href: '/casino/blackjack',
        icon: <DoubleCard className="w-7 h-7 shrink-0" />,
      },
      // {
      //   gameName: 'Lettery',
      //   href: '',
      // },
      // {
      //   gameName: 'Baccarat',
      //   href: '',
      // },
      // {
      //   gameName: 'Blackjack',
      //   href: '/casino/blackjack',
      // },
    ],
  },
  {
    label: 'Sports',
    icon: <GamesHeader className="w-7 h-7 shrink-0" />,
    href: '/sports/baseball',
    page: 'sports',
    child: [
      {
        icon: <Sports className="w-7 h-7 shrink-0 text-yellow-600" />,
        label: 'Games',
        href: '/sports/popular-events',
      },
      {
        icon: <ClockIcon className="w-7 h-7 shrink-0 text-red-500" />,
        label: 'Pools',
        href: '/sports/pools',
      },
      {
        icon: <Awards className="w-7 h-7 shrink-0 text-yellow-500" />,
        label: 'Futures',
        href: '/sports/futures',
      },
      {
        icon: <VideoCameraIcon className="w-7 h-7 shrink-0 text-red-500" />,
        label: 'Live Events',
        href: '/live',
      },
      {
        icon: <StarIcon className="w-7 h-7 shrink-0 text-yellow-500" />,
        label: 'Favorites',
        href: '/favourites',
      },
      {
        icon: <SingleTicket className="w-7 h-7 shrink-0 text-green-500" />,
        label: 'My Bets',
        href: '/bets',
      },
    ],
    sportChild: [
      {
        heading: 'Popular',
        subChilds: [
          {
            icon: Nhl,
            label: 'NHL',
            href: '/sports/hockey?league=NHL',
          },
          {
            icon: Nba,
            label: 'NBA',
            href: '/sports/basketball?league=NBA',
          },
          {
            icon: Mlb,
            label: 'MLB',
            href: '/sports/baseball?league=MLB',
          },
        ],
      },
    ],
    sportEvents: [
      {
        icon: VideoCameraIcon,
        label: 'Live Events',
        href: '/live',
      },
      {
        icon: StarIcon,
        label: 'Favorites',
        href: '/favourites',
      },
      {
        icon: SingleTicket,
        label: 'My Bets',
        href: '/bets',
      },
    ],
  },
]
const MenuNavigation = () => {
  const navigation = [
    {
      icon: <SingleTicket className="w-7 h-7 shrink-0" />,
      label: 'navLeftBar.myBets',
      defaultLabel: 'My Bets',
      href: '/bets',
      page: 'bets',
    },
    {
      label: 'navbar.sports',
      defaultLabel: 'Sports',
      icon: <GamesHeader className="w-7 h-7 shrink-0" />,
      href: '/sports/popular-events',
      page: 'sports',
    },
  ]
  return (
    <>
      {navigation.map((item, index) => (
        <HeaderButton key={index} {...item} header bottom />
      ))}
    </>
  )
}

const Accordion = ({
  title,
  center,
  children,
  icon,
  games,
  sports1,
  events,
  showIcons = true,
  onClick,
}) => {
  const { pathname, push } = useRouter()
  const [isOpen, setIsOpen] = useState(
    pathname === '/casino/plinko' ? title == 'Casino' : title == 'Sports',
  )

  const { sports, ...rest } = useSidebar()
  const dispatch = useDispatch()

  const sportsData = useMemo(() => {
    return (
      (sports?.length && sports?.map((sport) => getSportByName(sport))) || []
    )
  }, [sports])

  const sortedSports = useMemo(() => {
    return sportsData?.sort((a, b) => a.index - b.index)
  }, [sportsData])

  const gameHandler = (href) => {
    push(href)
    onClick()
  }

  const sportHandler = (sport) => {
    rest?.setActiveSport(sport)
    setTimeout(() => {
      onClick()
    }, 1000)
  }
  const onClickMenu = (title) => {
    setIsOpen(!isOpen)
    dispatch(setBottomTabLabel(title))
  }
  return (
    <div className="w-full  rounded-lg overflow-hidden">
      <div
        className="flex items-center  py-2.5 px-4"
        onClick={() => onClickMenu(title)}
      >
        <div
          className={`font-medium flex-1 text-base capitalize  flex items-center gap-x-2 ${
            center ? 'text-center ' : 'text-left '
          }`}
        >
          {icon}
          {title}
        </div>
        <div className="w-[20px] h-[20px] rounded-full flex justify-center items-center cursor-pointer ">
          {isOpen ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 7.5L10 12.5L15 7.5"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 12.5L10 7.5L5 12.5"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="px-6 pb-3">
          <div className="grid grid-cols-3 gap-2 w-full mb-2">
            {children &&
              children.map((child, idx) => (
                <div
                  key={idx}
                  className={`font-medium flex-1 text-base capitalize  mb-2 flex flex-col items-center gap-y-1 `}
                >
                  <Link
                    href={child.href}
                    className="flex flex-col items-center "
                  >
                    {child.icon}
                    {child.label}
                  </Link>
                </div>
              ))}
          </div>
          {games &&
            games?.map((game, idx) => (
              <div key={idx} className="py-3  ">
                <Link href={game?.href} className="flex items-center space-x-2">
                  {game.icon}
                  <span>{game?.gameName}</span>
                </Link>
              </div>
            ))}
          {sports1 &&
            sports1.map((child, idx) => (
              <div
                key={idx}
                className={`font-medium flex-1 text-base capitalize  mb-2  ${
                  center ? 'text-center ' : 'text-left '
                }`}
              >
                <div className="mb-4 -mx-4">{child.heading}</div>
                {child.subChilds.map((children, idx) => (
                  <div
                    key={idx}
                    className="flex items-center gap-x-2  my-2"
                    onClick={() => gameHandler(children.href)}
                  >
                    <div className="w-8 h-8 overflow-hidden rounded-md bg-white flex justify-center items-center">
                      <children.icon />
                    </div>
                    {children.label}
                  </div>
                ))}
                <div className="mt-4 -mx-4">All Sports</div>
                <div className="flex flex-col w-full relative sm:items-center -mx-4">
                  {showIcons &&
                    sortedSports?.length > 0 &&
                    sortedSports?.map((sport, index) => (
                      <SecondaryIconButton
                        key={index}
                        sport={sport}
                        appendedData={rest}
                        isOpen={isOpen}
                        onClick={() => sportHandler(sport?.name)}
                      />
                    ))}
                  {showIcons && (
                    <>
                      <SecondaryIconButton
                        sport={{
                          name: 'motorsports',
                          logo: F1,
                        }}
                        appendedData={rest}
                        isOpen={isOpen}
                        onClick={() => push('/sports/futures/motorsports')}
                      />
                      <SecondaryIconButton
                        sport={{
                          name: 'golf',
                          logo: Golf,
                        }}
                        appendedData={rest}
                        isOpen={isOpen}
                        onClick={() => push('/sports/futures/golf')}
                      />
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

function BottomBar() {
  const { pathname } = useRouter()
  const dispatch = useDispatch()
  const { slips: listingData } = useSelector(betSlipsSelector)
  const [show, setShow] = React.useState(false)
  const { user } = useSelector(authSelector)

  const onClickMenu = () => {
    setShow(!show)
  }

  const betHandler = () => {
    dispatch(toggleBetslips())
    dispatch(toggleBetSlipsMobile())
  }

  return (
    <ReactTransaction in={show} timeout={300}>
      {(state) => (
        <Popover
          style={{
            ...contentStyle(300),
            ...contentTransitionStyles[state],
          }}
        >
          <Transition
            timeout={300}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            show={show}
          >
            <Popover.Panel
              focus
              className={`fixed z-30 top-0 inset-x-0 p-2 transition`}
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-[#0e1411] divide-y-2 divide-gray-50  ">
                <div className="pt-5 px-2 ">
                  <div className={`flex items-center justify-between`}>
                    <Link href="/home" className="relative block">
                      <LilbitLogo className="mr-8 cursor-pointer w-24 h-24" />
                    </Link>
                    <Popover.Button as={Fragment}>
                      <div
                        onClick={() => setShow(false)}
                        className={
                          'text-white rounded-lg cursor-pointer w-10 h-10 flex items-center justify-center hover:bg-[#fff]/10'
                        }
                      >
                        <XIcon className="w-5 h-5 " />
                      </div>
                    </Popover.Button>
                  </div>
                  <nav className="mb-[3rem] ">
                    <div className="overflow-auto h-[84vh] ">
                      {[...menus].map((item, index) => (
                        <div key={index}>
                          <div className="flex justify-between items-center  ">
                            <Accordion
                              title={item.label}
                              icon={item.icon}
                              games={item.games}
                              sports1={item.sportChild}
                              events={item.sportEvents}
                              onClick={onClickMenu}
                            >
                              {item.child}
                            </Accordion>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </nav>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
          <nav className="fixed bottom-0 flex justify-between items-center px-4  bg-white/10 h-[70px] sm:hidden backdrop-blur-md z-20 border-inherit w-full">
            <div onClick={onClickMenu}>
              <HeaderButton
                label="button.menu"
                icon={<MenuAlt2Icon className="w-7 h-7 shrink-0" />}
                defaultLabel="Menu"
                bottom
              />
            </div>
            <div onClick={onClickMenu}>
              <HeaderButton
                label="navbar.casino"
                icon={<Casino className="w-7 h-7 shrink-0" />}
                defaultLabel="Casino"
                bottom
              />
            </div>
            <MenuNavigation />
            {(pathname === '/sports/[sport]' ||
              pathname === '/home' ||
              pathname === '/live' ||
              pathname === '/sports/[sport]/[betId]' ||
              pathname === '/sports/[sport]/props' ||
              pathname === '/sports/futures/[slug]' ||
              pathname === '/sports/popular-events') && (
              <div onClick={betHandler} className="relative" id="targetDiv">
                <HeaderButton
                  label="button.betSlip"
                  icon={<BetSlipIcon className="w-7 h-7 shrink-0" />}
                  defaultMessage="Bet Slip"
                  bottom
                />
                {user?.roleType !== 'admin' && (
                  <div className="absolute right-3 sm:right-4 -top-2 sm:-top-1 rounded-full w-[18px] h-[18px] sm:w-[24px] sm:h-[22px] bg-[#F7941D] flex items-center justify-center py-3 px-3 text-xs sm:text-sm font-bold">
                    {listingData.length}
                  </div>
                )}
              </div>
            )}
          </nav>
        </Popover>
      )}
    </ReactTransaction>
  )
}

export default React.memo(BottomBar)

import { BellIcon, KeyIcon } from '@heroicons/react/outline'
import { Profile, SingleTicket, Wallet } from '../icons/index'

export const NumberChip = ({ number, number2, className = '' }) => {
  return (
    <button
      className={`${
        className ? className : 'bg-white/10'
      } rounded-2xl px-2 py-0.5 font-sans font-medium text-sm whitespace-nowrap flex ${
        number2 ? ' gap-x-2' : ''
      }`}
    >
      <span>{number || '0'}</span>
      <span>{number2}</span>
    </button>
  )
}

// For BetSlip Modal
export const NumberChip2 = ({ number, number2, className, slipData }) => (
  <button
    className={`${
      className ? className : 'bg-[#F7941D]/20'
    } px-[.8rem] py-[.25rem] rounded-2xl font-medium text-xs flex ${
      number2 ? 'gap-x-2' : ''
    }`}
  >
    {slipData?.name?.substr(0, 5) === 'Under' ||
    slipData?.name?.substr(0, 4) === 'Over' ? (
      ''
    ) : (
      <span>{number2}</span>
    )}

    <span>{number || 0}</span>
  </button>
)

export const renderNumber = (number) => {
  return number?.toString()?.includes('%')
    ? number
    : Number(number) > 0
    ? `+${number}`
    : number
}

export const renderNumber2 = (number) => {
  if (number > 0) {
    return `+${number}`
  }
  return number
}

export const tabNavigation = [
  {
    // name: 'Profile',
    name: 'navbar.authUser.profile',
    defaultName: 'Profile',
    active: true,
    index: 1,
    href: '/settings?tab=profile',
    icon: <Profile className="w-5 h-5 text-green-700" />,
  },
  {
    // name: 'Wallet',
    name: 'navbar.authUser.wallet',
    defaultName: 'Wallet',
    index: 2,
    href: '/settings?tab=wallet',
    icon: <Wallet className="w-5 h-5 text-green-700" />,
  },
  {
    name: 'navbar.authUser.notification',
    defaultName: 'Notification',
    index: 3,
    href: '/settings?tab=notification',
    icon: <BellIcon className="w-5 h-5 text-green-700" />,
  },
  {
    name: 'navbar.authUser.security',
    defaultName: 'Security',
    index: 4,
    href: '/settings?tab=security',
    icon: <KeyIcon className="w-5 h-5 text-green-700" />,
  },
  {
    name: 'navbar.authUser.affilates',
    defaultName: 'Affilates',
    index: 5,
    href: '/settings?tab=affilates',
    icon: <SingleTicket className="w-5 h-5 text-green-700" />,
  },
]

import React, { useEffect, useState } from 'react'
import { Lbt } from '@/icons/index'

const CustomImage = ({ src, cls, alt = '' }) => {
  const [imgError, setImgError] = useState(false)

  const handleImgError = () => {
    setImgError(true)
  }

  useEffect(() => {
    setImgError(false)
  }, [src])

  return (
    <>
      {imgError || !src ? (
        <Lbt className={cls} />
      ) : (
        <img
          src={src}
          onError={handleImgError}
          alt={alt || 'team-logo'}
          className={cls}
        />
      )}
    </>
  )
}

export default CustomImage

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {
  getDatabase,
  ref,
  onValue,
  off,
  push,
  query,
  orderByKey,
  limitToLast,
  set,
} from 'firebase/database'
import { errorMsg, warnMsg, successMsg } from '@/utils/toast'
import NextLink from 'next/link'
import AboutModal from './aboutModal'
import ColorsModal from './colorsModal'
import {
  firebasaSendComplaints,
  firebasaSendWarning,
  firebaseBanOrUnban,
  firebaseCheckUserChatStatus,
  firebaseGetHappyHourStatus,
  firebaseRemoveText,
  firebaseRemoveUserOptions,
  firebaseSendtoAdmin,
  firebaseUpdateUserOptions,
  firebaseWarningConfirmation,
} from '@/firebase/utils'
import { nameColors, textColors } from './config'
import ReportModal from './reportModal'
import { Trash, Block, Report } from '@/icons/index'
import BlockUserModal from './blockUserModal'
import { setUserBlockList } from '@/redux/slices/auth'
import SettingsDropdown from './settingsDropdown'
import UnbanModal from './unbanModal'
import { useIntl } from 'react-intl'
import BanInformationModal from './banInformationModal'
import WarningModal from './warningModal'
import { messagesGenerator } from './helpers'
import RemoveTextModal from './removeTextModal'
import { SparklesIcon } from '@heroicons/react/outline'
import CustomImage from '@/utils/customImage'

const defaultChatStates = {
  colorsModal: false,
  reportModal: false,
  removeTextModal: false,
  blockModal: false,
  warningModal: false,
  unbanModal: false,
  banInformationModal: false,
  settingsDropdown: false,
  message: null,
}

const db = getDatabase()
const Chat = ({ user, paymail }) => {
  const tag = '@mods'
  const dispatch = useDispatch()
  const isAdmin = user?.roleType === 'admin'
  const inputRef = useRef(null)
  const intl = useIntl()
  const [modalStates, setModalStates] = useState(defaultChatStates)
  const [isHappyHour, setIsHappyHour] = useState(false)
  const [emojiPickerShown, setEmojiPickerShown] = useState(false)
  const [nameColor, setNameColor] = useState(
    user?.chatOptions?.nameColor || 'white',
  )
  const [textColor, setTextColor] = useState(
    user?.chatOptions?.textColor || 'gray',
  )
  const [text, setText] = useState('')
  const scrollbarRef = useRef(null)
  const [messagesList, setMessagesList] = useState({})
  const [reason, setReason] = useState('')
  const [banReason, setBanReason] = useState('')

  const handleClickOutside = (e) => {
    if (emojiPickerShown) {
      setEmojiPickerShown(false)
    }
  }
  const handleEmojiClick = (e) => {
    e.stopPropagation()
    setEmojiPickerShown(!emojiPickerShown)
  }

  const onEmojiClick = (e) => {
    const sym = e.unified.split('-')
    const codeArray = []
    sym.forEach((el) => codeArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codeArray)
    setText(text + emoji)
    inputRef.current.focus()
    setEmojiPickerShown(false)
  }

  const timeHandler = (time) => {
    const lastTime = Date.now() - time
    if (lastTime < 1000) {
      return 'Just Now'
    } else if (lastTime < 60000) {
      return `${Math.floor(lastTime / 1000)} seconds`
    } else if (lastTime < 3600000) {
      return `${Math.floor(lastTime / 60000)} minutes`
    } else if (lastTime < 86400000) {
      return `${Math.floor(lastTime / 3600000)} hours`
    } else {
      return `${Math.floor(lastTime / 86400000)} days`
    }
  }

  useEffect(() => {
    scrollbarRef.current?.scrollIntoView()
  }, [messagesList, isHappyHour])

  const checkUserWarningOrStatus = async (uid) => {
    return await firebaseCheckUserChatStatus(uid)
  }

  const addMessages = async (e) => {
    if (!user?.uid)
      return warnMsg(
        intl.formatMessage({
          id: 'warn.mustBeLoggedIn',
          defaultMessage: 'You must be logged in to send messages.',
        }),
      )
    const userStatus = await checkUserWarningOrStatus(user.uid)
    if (
      userStatus &&
      userStatus.filter((status) => status?.isBan || !status?.isRead).length
    ) {
      const banStatus = []
      const warningStatus = []
      userStatus.forEach((status) => {
        if (status?.isBan) banStatus.push(status)
        if (!status?.isRead) warningStatus.push(status)
      })

      if (banStatus.length) {
        return setModalStates((state) => ({
          ...state,
          banInformationModal: true,
          message: banStatus[0],
        }))
      }

      if (warningStatus.length) {
        return setModalStates((state) => ({
          ...state,
          warningModal: true,
          message: warningStatus[0],
        }))
      }
    } else {
      if (text.trim() === '')
        return warnMsg(
          intl.formatMessage({
            id: 'warn.messageTooShort',
            defaultMessage: 'Message must be at least 1 character long.',
          }),
        )
      if (text.length > 500)
        return warnMsg(
          intl.formatMessage({
            id: 'warn.messageTooLong',
            defaultMessage: 'Message must be less than 500 characters long.',
          }),
        )
      if (text.includes('register?ref')) {
        return warnMsg(
          intl.formatMessage({
            id: 'warn.noReferralLinks',
            defaultMessage: 'Links containing referrals not be allowed.',
          }),
        )
      }
      const date = Date.now()
      const createdDate = new Date()
      const messages = {
        uid: user.uid,
        username: user.username,
        photoPATH: user.photoPATH,
        paymail: paymail,
        text,
        time: date,
        createdDate,
        chatOptions: {
          nameColor,
          textColor,
        },
      }
      const chatRef = ref(db, 'chat')
      const newChatRef = push(chatRef)
      const newChatKey = newChatRef.key
      const dataWithKey = {
        ...messages,
        id: newChatKey,
      }
      set(newChatRef, dataWithKey)
      if (text.includes(tag)) {
        firebaseSendtoAdmin(messages)
      }
      setText('')
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      addMessages()
    }
  }

  useEffect(() => {
    const chatRef = ref(db, 'chat')
    const qry = query(chatRef, orderByKey(), limitToLast(100))
    onValue(
      qry,
      (snapshot) => {
        const dbMessages = snapshot.val()
        if (dbMessages) {
          setMessagesList(dbMessages)
        }
      },
      (error) => {
        console.error('Error fetching plinko data:', error)
      },
    )
    return () => {
      off(qry)
    }
  }, [])

  useEffect(() => {
    firebaseGetHappyHourStatus().then((res) => {
      if (res && !res?.error) {
        setIsHappyHour(res?.isActive)
      }
    })
  }, [])

  const textHandler = (e) => {
    setText(e.target.value)
  }

  const highlightMessages = (message) => {
    if (message?.isRemove) {
      return <span className="text-gray-600">&#60;message deleted&#62;</span>
    } else {
      const {
        text,
        chatOptions: { textColor: textC },
      } = message

      const linkRegex = /(https?:\/\/[^\s]+)/g
      const usernameRegex = /@([A-Za-z0-9]+)/g

      const parts = text.split(linkRegex)

      const finalText = parts
        .map((part) => {
          if (part.match(linkRegex)) {
            return `<a class="break-all text-gray-600 font-bold underline underline-offset-2 decoration-gray-500" href="${part}" target="_blank" rel="noopener noreferrer">${part}</a>`
          } else {
            return part.replace(usernameRegex, (match, username) => {
              const isCurrentUser =
                user && username.toLowerCase() === user.username.toLowerCase()
              const className = isCurrentUser
                ? 'text-green-700 break-all bg-white font-bold'
                : match === tag
                ? 'font-bold break-all text-white bg-red-700'
                : 'font-bold break-all'

              return `<span class="${className}">${match}</span>`
            })
          }
        })
        .join('')

      return (
        <span
          style={{ color: textColors[textC] }}
          className="break-all"
          dangerouslySetInnerHTML={{ __html: finalText }}
        />
      )
    }
  }

  const highlightUsernames = (message) => {
    const {
      username,
      chatOptions: { nameColor: nameC },
    } = message
    return (
      <div
        style={{ color: nameColors[nameC] }}
        className="font-bold has-tooltip relative"
      >
        {username.length > 10 && (
          <span
            style={{ color: nameColors[nameC] }}
            className="tooltip py-1 rounded -mt-6 w-32 bg-white/10"
          >
            {username}
          </span>
        )}
        {username.length > 10 ? username.slice(0, 8) + '...' : username}
      </div>
    )
  }

  const optionButonsHandler = (message, type) => {
    setModalStates((state) => ({
      ...state,
      [type]: true,
      message,
    }))
  }

  const onSave = (chatOptions) => {
    if (user) {
      firebaseUpdateUserOptions(user?.uid, chatOptions, 'chatOptions').then(
        (res) => {
          if (res) {
            setNameColor(chatOptions.nameColor)
            setTextColor(chatOptions.textColor)
          }
          messagesHandler(res, 'options')
        },
      )
    } else {
      errorMsg(
        intl.formatMessage({
          id: 'error.login.accessFeature',
          defaultMessage: 'Please log in to access this feature.',
        }),
      )
    }
  }

  const reportUser = () => {
    if (isAdmin) {
      firebasaSendWarning({
        ...modalStates.message,
        reason,
      }).then((res) => {
        messagesHandler(res, 'warning')
      })
    } else {
      if (reason.length < 10 || reason.length > 50) {
        return warnMsg(
          intl.formatMessage({
            id: 'warning.complaint.length',
            defaultMessage:
              'Please ensure your complaint is between 10 and 50 characters in length. Provide a concise description to help us address your concerns effectively. Thank you for your cooperation.',
          }),
        )
      }
      firebasaSendComplaints({
        ...modalStates.message,
        reason,
        senderUid: user.uid,
      }).then((res) => {
        messagesHandler(res, 'complaints')
      })
    }
  }

  const messagesHandler = (res, type) => {
    if (!res?.error) {
      const messages = messagesGenerator(type, 'success')
      setBanReason('')
      setReason('')
      setModalStates(defaultChatStates)
      successMsg(
        intl.formatMessage({
          id: 'success.blocked',
          defaultMessage: messages,
        }),
      )
    } else {
      const messages = messagesGenerator(type, 'error')
      errorMsg(
        intl.formatMessage({
          id: 'error.block.failed',
          defaultMessage: messages,
        }),
      )
    }
  }

  const blockUser = (blockOptions) => {
    if (isAdmin) {
      firebaseBanOrUnban({
        ...blockOptions,
        reason: banReason,
      }).then((res) => {
        messagesHandler(res, 'ban')
      })
    } else {
      if (user) {
        firebaseUpdateUserOptions(user?.uid, blockOptions, 'blockedUsers').then(
          (res) => {
            dispatch(setUserBlockList(res))
            messagesHandler(res, 'block')
          },
        )
      } else {
        errorMsg(
          intl.formatMessage({
            id: 'error.login.accessFeature',
            defaultMessage: 'Please log in to access this feature.',
          }),
        )
      }
    }
  }

  const onColorsOpen = () =>
    setModalStates((state) => ({ ...state, colorsModal: true }))

  const onUnbanOpen = () =>
    setModalStates((state) => ({ ...state, unbanModal: true }))

  const onBlockUser = (uid) => {
    if (user) {
      firebaseRemoveUserOptions(user?.uid, uid).then((res) => {
        if (!res?.error) {
          dispatch(setUserBlockList(res))
        }
        messagesHandler(res, 'unblock')
      })
    } else {
      errorMsg(
        intl.formatMessage({
          id: 'error.login.accessFeature',
          defaultMessage: 'Please log in to access this feature.',
        }),
      )
    }
  }

  const onUserBanned = () => {
    firebaseBanOrUnban(
      {
        ...modalStates.message,
        answerUser: banReason,
      },
      false,
    ).then((res) => {
      messagesHandler(res, 'userBan')
    })
  }

  const onUserWarning = () => {
    firebaseWarningConfirmation({
      ...modalStates.message,
    }).then((res) => {
      messagesHandler(res, 'warningUser')
    })
  }

  const onRemoveText = () => {
    firebaseRemoveText({
      ...modalStates.message,
    }).then((res) => {
      messagesHandler(res, 'removeMessage')
    })
  }

  return (
    <div className="flex-1 relative p-6 flex flex-col bg-black/80 min-w-full w-full sm:w-96 h-[calc(100dvh-72px)] left-0 right-0 bottom-0">
      <div className="flex mt-2 sm:items-center justify-between py-3 border-b-2 border-gray-200">
        <AboutModal />
        <ColorsModal
          onSave={onSave}
          nameColor={nameColor}
          textColor={textColor}
          isOpen={modalStates.colorsModal}
          setIsOpen={setModalStates}
        />
        <SettingsDropdown colorsModal={onColorsOpen} unbanModal={onUnbanOpen} />
        <ReportModal
          onSend={reportUser}
          isAdmin={isAdmin}
          reason={reason}
          setReason={setReason}
          isOpen={modalStates.reportModal}
          message={modalStates.message}
          setIsOpen={setModalStates}
        />
        <BlockUserModal
          onSend={blockUser}
          isOpen={modalStates.blockModal}
          isAdmin={isAdmin}
          message={modalStates.message}
          setIsOpen={setModalStates}
          banReason={banReason}
          setBanReason={setBanReason}
        />
        <UnbanModal
          onSend={onBlockUser}
          isOpen={modalStates.unbanModal}
          blockedList={user?.blockedUsers}
          setIsOpen={setModalStates}
        />
        <BanInformationModal
          onSend={onUserBanned}
          isOpen={modalStates.banInformationModal}
          message={modalStates.message}
          banReason={banReason}
          setBanReason={setBanReason}
          setIsOpen={setModalStates}
        />
        <WarningModal
          onSend={onUserWarning}
          isOpen={modalStates.warningModal}
          message={modalStates.message}
          setIsOpen={setModalStates}
        />
        <RemoveTextModal
          onSend={onRemoveText}
          isOpen={modalStates.removeTextModal}
          message={modalStates.message}
          setIsOpen={setModalStates}
        />
      </div>
      <ul
        id="messages"
        className="flex flex-col flex-1 space-y-4 p-3 overflow-y-auto"
      >
        {Object.values(messagesList).length > 0 &&
          Object.values(messagesList).map((message, key) => {
            if (!user?.blockedUsers?.hasOwnProperty(message.uid)) {
              return (
                <li key={key} className="flex space-x-2">
                  <div className="w-8 h-8 relative shrink-0">
                    <CustomImage
                      src={message.photoPATH}
                      cls="w-8 h-8 object-cover rounded-full"
                    />
                  </div>
                  <div className="mt-2 w-full flex flex-col space-y-2 group/container">
                    <div className="text-xs h-7 w-full font-semibold flex items-center justify-between space-x-2">
                      <div className="flex space-x-2">
                        <span>{highlightUsernames(message)}</span>
                        <span className="bg-gray-700 text-gray-300 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full">
                          {timeHandler(message.time)}
                        </span>
                      </div>
                      <div
                        className={`${
                          user && message.uid !== user.uid
                            ? 'space-x-2 group-hover/container:flex hidden'
                            : 'hidden'
                        }`}
                      >
                        <button
                          onClick={() =>
                            optionButonsHandler(message, 'reportModal')
                          }
                          className="border group/report text-white border-gray-700 hover:bg-gray-300 focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center"
                        >
                          <Report
                            className={`text-gray-300 group-hover/report:text-gray-800 group-hover/report:stroke-gray-800 w-4 h-4`}
                          />
                        </button>
                        <button
                          onClick={() =>
                            optionButonsHandler(message, 'blockModal')
                          }
                          className="border group/block text-gray-300 border-gray-700 hover:bg-gray-300 focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center"
                        >
                          <Block
                            className={`text-gray-300 group-hover/block:text-gray-800 group-hover/block:stroke-gray-800 w-4 h-4`}
                          />
                        </button>
                        {isAdmin && !message?.isRemove && (
                          <button
                            onClick={() =>
                              optionButonsHandler(message, 'removeTextModal')
                            }
                            className="border group/block text-gray-300 border-gray-700 hover:bg-gray-300 focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center"
                          >
                            <Trash
                              className={`text-gray-300 group-hover/block:text-gray-800 group-hover/block:stroke-gray-800 w-4 h-4`}
                            />
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col flex-1 text-xs  items-start">
                      <div className="flex flex-col">
                        <div className="px-4 py-2 flex flex-col text-left rounded-lg text-sm font-semibold bg-gray-300">
                          {highlightMessages(message)}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            }
          })}
        {isHappyHour && (
          <li>
            <div className="mt-2 w-full flex flex-col space-y-2 group/container">
              <div className="flex flex-col flex-1 text-xs  items-start">
                <div className="flex flex-col">
                  <div className="px-4 py-2 flex flex-col text-left rounded-lg text-sm font-semibold bg-gray-700">
                    <div className="flex space-x-1 justify-center items-center">
                      <div className="small sand-clock"></div>
                      <SparklesIcon className="w-5 h-5 text-yellow-300" />
                      <span className="text-yellow-100 text-base underline underline-offset-2 decoration-yellow-200">
                        Happy Hours Active!
                      </span>
                      <SparklesIcon className="w-5 h-5 text-yellow-300" />
                      <div className="small sand-clock"></div>
                    </div>
                    <div className="mt-4">
                      {' '}
                      Bonus plinko line is open.{' '}
                      <NextLink
                        href={'/casino/plinko'}
                        className="text-yellow-400 underline underline-offset-2 decoration-green-100"
                      >
                        Play now
                      </NextLink>{' '}
                      to get 9999x chance of winning!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
        <div ref={scrollbarRef} />
        {isHappyHour && (
          <>
            <div className="firework firework-one"></div>
            <div className="firework firework-second"></div>
            <div className="firework firework-third"></div>
            <div className="firework firework-fourth"></div>{' '}
          </>
        )}
        {emojiPickerShown && (
          <div className="absolute bottom-20 left-4">
            <Picker
              data={data}
              onClickOutside={handleClickOutside}
              onEmojiSelect={onEmojiClick}
            />
          </div>
        )}
      </ul>
      <div className="border-t-2 border-gray-200  pt-4 mb-2 sm:mb-0">
        <div className="relative flex">
          <input
            type="text"
            ref={inputRef}
            value={text}
            onChange={textHandler}
            onKeyDown={handleKeyDown}
            placeholder="Write your message!"
            className="w-full pr-20 focus:outline-none focus:outline-offset-0 border-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600  bg-gray-200 rounded-md py-3"
          />
          <div className="absolute right-0 items-center inset-y-0 flex">
            <button
              type="button"
              onClick={handleEmojiClick}
              className="inline-flex items-center justify-center rounded-full transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-6 w-6 text-gray-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </button>

            <button
              type="button"
              onClick={addMessages}
              className="inline-flex items-center justify-center rounded-md px-1 transition duration-500 ease-in-out text-cs-green focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-6 w-6 ml-2 transform rotate-90"
              >
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chat

import dynamic from 'next/dynamic'
import { XIcon } from '@heroicons/react/solid'

const Model = dynamic(() => import('@/components/model'), {
  ssr: false,
})

export default function UnbanModal({ onSend, isOpen, blockedList, setIsOpen }) {
  const onClose = () => setIsOpen((state) => ({ ...state, unbanModal: false }))

  const onHandle = (uid) => {
    onSend(uid)
  }

  return (
    <Model
      onClose={onClose}
      isOpen={isOpen}
      modelBG="bg-black/90"
      className="px-6 overflow-auto max-w-xl mt-[-5vh]"
    >
      <div className="w-full p-4 flex flex-col justify-start">
        <h2 className="text-2xl mt-0 text-center ">Blocked Users</h2>
      </div>
      <div
        className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
        onClick={onClose}
      >
        <XIcon className="w-5 h-5 text-gray-300" />
      </div>

      <div className="flex flex-wrap gap-2">
        {Object.values(blockedList || {}).length > 0 ? (
          Object.values(blockedList).map((user, key) => (
            <span
              key={key}
              id="badge-dismiss-red"
              className="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-red-100 bg-red-700 rounded "
            >
              {user.username}
              <button
                type="button"
                onClick={() => onHandle(user.uid)}
                className="inline-flex items-center p-1  ms-2 text-sm text-red-100 bg-transparent rounded-sm hover:bg-red-500 hover:text-red-900"
                data-dismiss-target="#badge-dismiss-red"
                aria-label="Remove"
              >
                <XIcon className="w-5 h-5 text-gray-300" />
                <span className="sr-only">Remove badge</span>
              </button>
            </span>
          ))
        ) : (
          <div className="flex justify-center items-center mt-2">
            There is no blocked user.
          </div>
        )}
      </div>
    </Model>
  )
}

import { useState } from 'react'
import Model from '@/components/model'
import { React } from 'react'
import { XIcon } from '@heroicons/react/solid'
import { DiceIcon } from '@/icons/index'

const AboutModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => setIsOpen(false)
  const onOpen = () => setIsOpen(true)

  return (
    <div className="flex z-10">
      <button
        type="button"
        onClick={onOpen}
        className="focus:outline-none font-medium rounded-lg text-sm py-2.5 text-center inline-flex space-x-2 items-center"
      >
        <DiceIcon className="w-7 h-7" />
        <span className="font-bold text-white text-lg ">Chat Rules !</span>
      </button>

      <Model
        onClose={onClose}
        isOpen={isOpen}
        scroll
        modelBG="bg-black/90"
        className="px-6 overflow-auto md:max-h-screen h-[75vh]"
      >
        <div className="w-full p-4">
          <h2 className="text-red-400 text-3xl">Chat Rules</h2>

          <h3 className="text-left text-red-300 mt-1 text-2xl">
            General Rules
          </h3>
          <ul className="mt-5 text-left list-disc flex flex-col space-y-2">
            <li>One user per IP address</li>
            <li>Harassment of any kind will not be tolerated</li>
            <li>
              Use of information obtained in the chat is at your own risk! Be
              careful with links
            </li>
            <li>Begging is forbidden</li>
            <li>Use English whenever possible</li>
            <li>Don&apos;t post nonsense or spam</li>
            <li>Don&apos;t say &quot;Congrats&quot; for every lottery</li>
            <li>
              Don&apos;t post a bitcoin address unless someone explicitly asks
              for it
            </li>
            <li>
              This is a public chat, be careful with asking/posting personal
              information
            </li>
          </ul>

          <h3 className="text-left text-red-300 mt-5 text-2xl">Nicknames</h3>
          <ul className="mt-5 text-left list-disc flex flex-col space-y-2">
            <li>Use a short and readable nickname when possible</li>
            <li>Do not use a bitcoin address as a nick name</li>
          </ul>

          <h3 className="text-left text-red-300 mt-5 text-2xl">Links</h3>
          <ul className="mt-5 text-left list-disc flex flex-col space-y-2">
            <li>
              Links are allowed, as long as they aren&apos;t spam and relevant
              to the discussion
            </li>
            <li>Links containing referrals are not allowed</li>
          </ul>

          <h3 className="text-left text-red-500 mt-5 text-2xl">Chat Lotto</h3>
          <ul className="mt-5 text-left list-disc flex flex-col space-y-2">
            <li>Lotto is currently reserved to players only</li>
            <li>
              You need to place a valid bet of at least 0.01฿ in the last 24
              hours with an address, for that address to be eligible
            </li>
            <li>
              If you claim your lotto prize with a certain address, no one but
              you can use this address to claim a new lotto in the next 24 hours
            </li>
          </ul>

          <h3 className="text-left text-red-300 mt-5 text-2xl">Bans</h3>
          <ul className="mt-5 text-left list-disc flex flex-col space-y-2">
            <li>
              You will usually get a warning before a ban - you should listen to
              it!
            </li>
            <li>
              Do not discuss bans on the chat, this is not the place to argue!
              Write to{' '}
              <a href="mailto:support@lilb.it">
                <strong className="underline underline-offset-2">
                  support@lilb.it
                </strong>{' '}
              </a>
              if you feel a ban was abusive
            </li>
            <li>Users who are underage will be banned directly</li>
          </ul>

          <h3 className="text-left text-red-300 mt-5 text-2xl">
            Moderation team
          </h3>
          <ul className="mt-5 text-left list-disc flex flex-col space-y-2">
            <li>
              If you need help with anything, post &quot;
              <strong className="underline underline-offset-2">@mods</strong>
              &quot;
            </li>
            <li>
              Staff should be on around the clock, but it may take a few minutes
              to respond
            </li>
            <li>
              Please respect the mods&apos; decisions, report abuse to{' '}
              <a href="mailto:support@lilb.it">
                <strong className="underline underline-offset-2">
                  support@lilb.it
                </strong>
              </a>
            </li>
            <li>Tip your moderators for their great job!</li>
          </ul>

          <h3 className="text-left text-green-300 mt-5 text-2xl">
            How does the chat lottery work?
          </h3>
          <ul className="mt-5 text-left flex flex-col space-y-2">
            <li>
              After a bet plays, there is a small chance the lottery bot will
              wake up to award a prize in the chat room. When this happens, a
              person that was recently active in chat will be randomly selected
              to receive a gift! To claim your prize, you must also have placed
              a valid bet of at least 0.01฿ on Lil Bit within the past 24 hours
              (exceptions: moderators can post any address, and anybody can
              always use the tip jar address provided by the bot).
            </li>
          </ul>

          <h3 className="text-left text-green-300 mt-5 text-2xl">
            What is the shiny BONUS coin?
          </h3>
          <ul className="mt-5 text-left flex flex-col space-y-2">
            <li className="font-semibold">
              This is a bonus for plinko. every once in awhile a shiny ball
              comes down and doubles your win
            </li>
            <li>
              The &quot;sparkling&quot; coin bonus is a reward for players that
              is the equivalent of a 1% site rakeback (may vary from time to
              time). All bets have a small chance to win a bonus, although the
              chance is greater for bets of 0.01฿ or more. Bonus prizes can be
              up to 200% of the wager, and are automatically added to your
              game&apos;s result.
            </li>
          </ul>

          <h3 className="text-left text-green-300 mt-5 text-2xl">
            HAPPY HOUR BONUS
          </h3>
          <ul className="mt-5 text-left flex flex-col space-y-2">
            <li>
              A few times a week for an hour, we will have the happy hour bonus
              where we will show the 5th PLINKO line with the chance to win
              9999x your money.
            </li>
          </ul>
        </div>
        <div
          className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
          onClick={onClose}
        >
          <XIcon className="w-5 h-5 text-gray-300" />
        </div>
      </Model>
    </div>
  )
}

export default AboutModal

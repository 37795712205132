import React, { memo } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import authSelector from '@/redux/selectors/auth'
import { FormattedMessage } from 'react-intl'
import CustomImage from '@/utils/customImage'

function HeaderButton({
  icon,
  className,
  active,
  centered,
  label,
  defaultLabel,
  prefetch = false,
  href = '#',
  page,
  imgHeight,
  imgWidth,
  header,
  bottom,
  registerClass,
  loginClass,
  exploreClass,
  home,
  ...rest
}) {
  const router = useRouter()
  const { user } = useSelector(authSelector)
  const isAdmin = user?.roleType === 'admin'

  const onChange = () => {
    const currentPath = router.pathname
    if (href === currentPath) {
      return
    }
    router.push(href)
  }

  const handleOnClick = () => {
    if (href === '#') return rest?.onClick
    router.push(href)
  }
  return (
    <>
      {isAdmin ? (
        <div>
          {label !== 'Bet Slip' && (
            <button
              className={`flex items-center justify-center space-x-2 text-white px-3 py-2 rounded-lg cursor-pointer ${
                registerClass
                  ? 'flex-row-reverse gap-x-2 bg-[#008000] w-full sm:w-auto'
                  : loginClass
                  ? 'flex-row-reverse gap-x-2 bg-[#FFFFFF]/10 w-full sm:w-auto'
                  : exploreClass
                  ? 'mt-2 flex-row-reverse gap-x-2 bg-[#008000]'
                  : ''
              } ${
                active ? `bg-[#fff]/10 ${className}` : 'hover:bg-[#fff]/10'
              } ${
                router.pathname.includes(page)
                  ? `bg-[#fff]/10 ${className}`
                  : 'hover:bg-[#fff]/10'
              }`}
              onClick={header ? onChange : handleOnClick}
              {...rest}
            >
              {bottom ? (
                <>
                  <div className="flex flex-col justify-center items-center ">
                    {icon}
                    <span className="responsive-text text-white font-medium font-inter pt-1 ">
                      <FormattedMessage
                        id={label}
                        defaultMessage={defaultLabel}
                      />
                    </span>
                  </div>
                </>
              ) : (
                !home && (
                  <>
                    {icon}
                    <span
                      className={`${
                        registerClass || loginClass
                          ? 'text-lg'
                          : exploreClass
                          ? 'text-sm'
                          : 'text-base'
                      }  font-medium font-inter whitespace-nowrap`}
                    >
                      <FormattedMessage
                        id={label}
                        defaultMessage={defaultLabel}
                      />
                    </span>
                  </>
                )
              )}
            </button>
          )}
        </div>
      ) : (
        <button
          className={`flex items-center text-xs space-x-2 text-white p-2 rounded-lg cursor-pointer ${
            registerClass
              ? 'justify-center flex-row-reverse gap-x-2 bg-[#008000] w-full sm:w-auto'
              : loginClass
              ? 'justify-center flex-row-reverse gap-x-2 bg-[#FFFFFF]/10 w-full sm:w-auto'
              : exploreClass
              ? 'justify-center mt-2 flex-row-reverse gap-x-2 bg-[#008000]'
              : ''
          }
${active ? `bg-[#fff]/10 ${className}` : 'hover:bg-[#fff]/10'} ${
            router.pathname.includes(page)
              ? `bg-[#fff]/10 ${className}`
              : 'hover:bg-[#fff]/10'
          }`}
          onClick={header ? onChange : handleOnClick}
          {...rest}
        >
          {bottom ? (
            <>
              <div className="flex flex-col justify-center items-center ">
                {icon}
                <span className="responsive-text text-white font-medium font-inter pt-1 ">
                  <FormattedMessage id={label} defaultMessage={defaultLabel} />
                </span>
              </div>
            </>
          ) : (
            !home && (
              <>
                {typeof icon === 'string' ? <CustomImage src={icon} /> : icon}

                <span
                  className={`${
                    registerClass || loginClass
                      ? 'text-lg'
                      : exploreClass
                      ? 'text-sm'
                      : 'text-sm xl:text-base'
                  }  font-inter whitespace-nowrap`}
                >
                  <FormattedMessage id={label} defaultMessage={defaultLabel} />
                </span>
              </>
            )
          )}
        </button>
      )}
    </>
  )
}
export default memo(HeaderButton)

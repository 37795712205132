import dynamic from 'next/dynamic'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleWarningModal } from '@/redux/slices/wallet'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import walletSelector from '@/redux/selectors/wallet'
import { HandCashIcon } from '@/icons/index'

const Model = dynamic(() => import('@/components/model'), {
  ssr: false,
})

export default function LimitWarningModal() {
  const dispatch = useDispatch()
  const { handcash } = useSelector(walletSelector)

  const closeModal = () => {
    dispatch(handleWarningModal({ warningModal: false, limitLink: '' }))
  }

  return (
    <>
      <Model
        onClose={closeModal}
        isOpen={handcash.warningModal}
        showCloseIcon
        modelBG="bg-black/90"
        className="px-6 overflow-auto max-h-screen"
      >
        <div className="space-y-8 max-w-sm w-[440px]">
          <div className="space-y-4">
            <div className="p-1 bg-white/5 w-fit rounded-full mx-auto relative">
              <ExclamationCircleIcon className="p-1.5 w-10 h-10 text-yellow-500 bg-white/5 rounded-full border-[3px] border-white/10" />
            </div>
            <div>
              <h3 className="font-inter font-medium text-lg">
                You have reached the daily spend limit.
              </h3>
              <p className="text-gray-400">
                Before continuing using this app, please reset the limit.
              </p>
            </div>
          </div>
          <div className="cursor-pointer flex items-center justify-center flex-col">
            <a
              href={handcash.limitLink}
              rel="noopener noreferrer"
              className="flex flex-row items-center justify-center rounded-lg py-3 hover:bg-white/[0.07] bg-white/[0.05] mt-1.5 w-full relative"
            >
              <HandCashIcon className="w-6 h-6" />
              <h3 className="font-inter font-medium text-md pl-3">
                Reset limit
              </h3>
            </a>
          </div>
        </div>
      </Model>
    </>
  )
}

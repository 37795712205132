import dynamic from 'next/dynamic'
import { XIcon } from '@heroicons/react/solid'

const Model = dynamic(() => import('@/components/model'), {
  ssr: false,
})

export default function BlockUserModal({
  onSend,
  isOpen,
  isAdmin,
  message,
  banReason,
  setBanReason,
  setIsOpen,
}) {
  const onClose = () =>
    setIsOpen((state) => ({ ...state, blockModal: false, message: null }))

  const onHandle = () => {
    const { uid, username } = message
    if (isAdmin) {
      onSend({
        ...message,
      })
    } else {
      onSend({
        [uid]: {
          uid,
          username,
        },
      })
    }
  }

  return (
    <Model
      onClose={onClose}
      isOpen={isOpen}
      modelBG="bg-black/90"
      className="px-6 overflow-auto max-w-xl mt-[-5vh]"
    >
      <div className="w-full p-4 flex flex-col justify-start">
        <h2 className="text-2xl mt-0 text-center ">Block User</h2>
      </div>
      <div
        className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
        onClick={onClose}
      >
        <XIcon className="w-5 h-5 text-gray-300" />
      </div>

      <p className="mt-5 text-left">
        Blocking{' '}
        <span className="underline underline-offset-2 text-red-400">
          {message?.username}
        </span>{' '}
        {isAdmin
          ? 'will no longer be able to use chat.'
          : "will prevent you from receiving any further messages from them, and you won't be able to see their messages. Are you sure you want to block"}{' '}
        Are you sure you want to block{' '}
        <span className="underline underline-offset-2 text-red-400">
          {message?.username}
        </span>
        ?
      </p>

      {isAdmin && (
        <>
          <label
            htmlFor="message"
            className="block mb-2 mt-2 text-left text-sm font-medium text-gray-400"
          >
            Explaining the reason for banning.
          </label>
          <textarea
            id="message"
            rows="4"
            value={banReason}
            onChange={(e) => setBanReason(e.target.value)}
            className="block p-2.5 w-full text-sm rounded-lg border-none outline-none focus:outline-none focus:outline-offset-0 bg-gray-700 border-gray-600 placeholder-gray-400 text-white"
            placeholder="Write your reason here..."
          ></textarea>
        </>
      )}

      <div className="flex justify-end items-center mt-2">
        <button
          onClick={onHandle}
          className="flex bg-cs-green px-5 py-1 rounded-md"
        >
          Block
        </button>
      </div>
    </Model>
  )
}

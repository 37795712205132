import dynamic from 'next/dynamic'
import { XIcon } from '@heroicons/react/solid'

const Model = dynamic(() => import('@/components/model'), {
  ssr: false,
})

export default function BanInformationModal({
  onSend,
  isOpen,
  message,
  banReason,
  setBanReason,
  setIsOpen,
}) {
  const onClose = () =>
    setIsOpen((state) => ({
      ...state,
      banInformationModal: false,
      message: null,
    }))

  const onHandle = () => {
    onSend({
      message,
    })
  }

  const generateDate = (date) => {
    if (date) {
      const startDate = new Date(0)
      const resultDate = new Date(
        startDate.getTime() + date.seconds * 1000 + date.nanoseconds / 1000000,
      )
      return resultDate.toUTCString()
    }
  }
  return (
    <Model
      onClose={onClose}
      isOpen={isOpen}
      modelBG="bg-black/90"
      className="px-6 overflow-auto max-w-xl mt-[-5vh]"
    >
      <div className="w-full p-4 flex flex-col justify-start">
        <h2 className="text-2xl mt-0 text-center ">You Are Banned</h2>
      </div>
      <div
        className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
        onClick={onClose}
      >
        <XIcon className="w-5 h-5 text-gray-300" />
      </div>
      <p className="mt-2 text-left">
        Reason: <span className="text-red-400">{message?.reason}</span>
      </p>
      <p className="mt-2 text-left">
        Message: &quot;<span className="text-red-400">{message?.text}</span>
        &quot;
      </p>
      <p className="mt-5 text-left">
        You were banned on{' '}
        <span className="underline underline-offset-2 text-red-400 decoration-stone-300">
          {generateDate(message?.bannedDate)}
        </span>{' '}
        for the following reason.{' '}
        {!message?.answerUser
          ? 'If you think there is an wrong, you can make a request.'
          : 'There is a request you have sent, you can view it here when it is answered.'}
      </p>

      {message && !message.answerUser && (
        <>
          <label
            htmlFor="message"
            className="block mb-2 mt-2 text-left text-sm font-medium text-gray-400"
          >
            You can make your statement here.
          </label>
          <textarea
            id="message"
            rows="4"
            value={banReason}
            onChange={(e) => setBanReason(e.target.value)}
            className="block p-2.5 w-full text-sm rounded-lg border-none outline-none focus:outline-none focus:outline-offset-0 bg-gray-700 border-gray-600 placeholder-gray-400 text-white"
            placeholder="Write your statement here..."
          ></textarea>

          <div className="flex justify-end items-center mt-2">
            <button
              onClick={onHandle}
              className="flex bg-cs-green px-5 py-1 rounded-md"
            >
              Send
            </button>
          </div>
        </>
      )}
      {message && message.answerAdmin && (
        <>
          <p className="mt-2 text-left">
            Answer:
            <span className="text-red-400">{message.answerAdmin}</span>
          </p>
        </>
      )}
    </Model>
  )
}

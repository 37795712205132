import React, { memo, useMemo } from 'react'
import { getSportByName } from '@/constants/countries-leagues'
import useSidebar from '@/hooks/useSidebar'
import { toggleSidebar } from '@/redux/slices/sidebar'
import { sidebarStyle, sidebarTransitionStyles } from '@/utils/transition'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Transition } from 'react-transition-group'
import { useMediaQuery } from 'react-responsive'
import { FormattedMessage, useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import { F1, Golf, LilbitLogoSecond, SingleTicket } from '@/icons/index'
import CustomImage from '@/utils/customImage'
import { StarIcon, VideoCameraIcon } from '@heroicons/react/outline'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/solid'

const IconButtons = [
  {
    route: 'live',
    href: '/live',
    title: 'navLeftBar.live',
    defaultTitle: 'Live',
    icon: <VideoCameraIcon className="w-7 h-7 shrink-0 text-red-500" />,
    alt: 'Live games icon',
  },
  {
    route: 'favourites',
    href: '/favourites',
    title: 'navLeftBar.favorites',
    defaultTitle: 'Favorites',
    icon: <StarIcon className="w-7 h-7 shrink-0 text-yellow-500" />,
    alt: 'Favourite games icon',
  },
  {
    route: 'bets',
    href: '/bets',
    title: 'navLeftBar.myBets',
    defaultTitle: 'My Bets',
    icon: <SingleTicket className="w-7 h-7 shrink-0 text-green-500" />,
    alt: 'Bets icon',
  },
]

const shrinkText = (text, length = 10) => {
  if (text?.length <= length) return text
  return text.slice(0, length) + '...'
}

const IconButton = ({ icon, href, isOpen, ...rest }) => {
  return (
    <Link href={href || '#'} prefetch={false}>
      <button
        className={`text-white h-12 flex items-center rounded-xl mouse-pointer ${
          isOpen ? 'px-4 w-[130px]' : 'justify-center w-[48px]'
        }`}
        {...rest}
      >
        {icon}
        {isOpen && <p className="ml-4">{rest.title}</p>}
      </button>
    </Link>
  )
}

export const SecondaryIconButton = ({
  sport,
  appendedData,
  isOpen,
  ...rest
}) => {
  const router = useRouter()
  const activeLeagues = [
    ...((appendedData?.leagues &&
      router?.query?.sport === sport.name &&
      appendedData?.leagues[sport?.name]) ||
      []),
  ]
  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  const intl = useIntl()

  const activeLeagueHandler = (leagueName) => {
    router.push(`/sports/${sport.name}?league=${leagueName}`)
  }
  return (
    <>
      <div
        className={`text-white my-1 flex items-center max-w-[calc(100%-10px)] relative${
          router?.query?.sport === sport.name
            ? `bg-[#008000]`
            : 'hover:bg-[#000000]/5 hover:backdrop-blur-sm'
        } cursor-pointer rounded-lg sm:px-3 py-2 sm:py-3 ${isOpen && 'w-full'}`}
        title={sport.name.charAt(0).toUpperCase() + sport.name.slice(1)}
        {...rest}
      >
        {typeof sport?.logo === 'function' ? (
          <sport.logo className="relative w-9 h-9 sm:w-10 sm:h-10 p-1.5" />
        ) : (
          <CustomImage
            src={sport?.logo}
            cls={'relative w-9 h-9 sm:w-10 sm:h-10 p-1.5'}
          />
        )}
        {isOpen && (
          <p className="ml-1">
            {sport.name.charAt(0).toUpperCase() + sport.name.slice(1)}
          </p>
        )}
      </div>
      {activeLeagues?.map((league, index) => {
        const isActive =
          league?.name?.toLowerCase() === router?.query?.league?.toLowerCase()
        return (
          <div
            key={index}
            className={`text-white shrink-0 mx-1 overflow-hidden flex items-center px-1 py-1 sm:px-0  max-w-[calc(100%-10px)] relative cursor-pointer rounded-lg ${
              isOpen && 'w-full'
            }`}
            onClick={() => activeLeagueHandler(league.name)}
            title={league.name}
          >
            <div
              className={`w-8 h-8 sm:w-11 sm:h-11 relative rounded-md overflow-hidden bg-white flex justify-center items-center ${
                isActive && !sm
                  ? `border-2 border-[#008000] shadow-md shadow-[#008000]/30`
                  : ''
              }`}
            >
              {typeof league?.logo === 'function' ? (
                <league.logo />
              ) : (
                <CustomImage src={league?.logo} />
              )}
            </div>
            {isOpen && (
              <p className="ml-4 text-sm flex-1 whitespace-nowrap">
                {sm
                  ? league.name
                  : shrinkText(
                      league.name.charAt(0).toUpperCase() +
                        league.name.slice(1),
                      5,
                    )}
              </p>
            )}
          </div>
        )
      })}
    </>
  )
}

const RandomLeagues = memo(({ isOpen, sport, appendedData, ...data }) => {
  const router = useRouter()
  const getAllLeagues = () => {
    const tempData = []
    const sports = [...new Set(Object.keys(data?.leagues || {}) || [])]

    sports?.forEach((sport) => {
      const leagues = data?.leagues[sport]
      leagues?.forEach((league) => {
        tempData?.push({
          ...league,
          sport: sport,
          visitIndex: data?.visitedLeagues?.[league?.name?.toLowerCase()] || 0,
        })
      })
    })
    return tempData
      ?.sort((a, b) => {
        return a?.visitIndex - b?.visitIndex
      })
      ?.reverse()
      ?.slice(0, 5)
  }

  const allLeagues = getAllLeagues()

  return (
    <div className="py-10 space-y-2 border-b border-white/10 w-full flex flex-col items-center">
      {allLeagues?.map((league, index) => (
        <div
          className={`relative text-white hover:bg-white/5 cursor-pointer flex items-center rounded-lg  max-w-[calc(100%-10px)] ${
            league?.name?.toLowerCase() === router?.query?.league?.toLowerCase()
              ? `bg-[#313439] shadow-md shadow-[#008000]/30 `
              : 'hover:bg-[#000000]/5 hover:backdrop-blur-sm'
          }  ${isOpen && 'w-full'}`}
          onClick={() => {
            data?.setActiveSport(league.sport, league.name)
          }}
          title={league.name}
          key={index + league.name}
        >
          <div className="relative h-12 w-12 rounded-md bg-white overflow-hidden flex justify-center items-center">
            {typeof league?.logo === 'function' ? (
              <league.logo />
            ) : (
              <CustomImage src={league?.logo} />
            )}
          </div>

          {isOpen && (
            <span className={`${'ml-4'} text-sm`}>
              {shrinkText(
                league.name.charAt(0).toUpperCase() + league.name.slice(1),
                5,
              )}
            </span>
          )}
        </div>
      ))}
    </div>
  )
})
RandomLeagues.displayName = 'RandomLeagues'

function Sportsidebar({ showIcons = true, isOpen }) {
  const dispatch = useDispatch()
  const router = useRouter()

  const { sports, ...rest } = useSidebar()
  const sportsData = useMemo(() => {
    return (
      (sports?.length && sports?.map((sport) => getSportByName(sport))) || []
    )
  }, [sports])

  const sortedSports = useMemo(() => {
    return sportsData?.sort((a, b) => a.index - b.index)
  }, [sportsData])

  const handleSidebar = () => {
    dispatch(toggleSidebar())
  }

  const intl = useIntl()
  return (
    <Transition in={isOpen} timeout={50}>
      {(state) => (
        <div
          style={{
            ...sidebarStyle(50),
            ...sidebarTransitionStyles[state],
            overflowX: 'hidden',
            msOverflowX: 'hidden',
          }}
          className={`scroller fixed hidden sm:flex text-white flex-col bg-[#fff]/5 backdrop-blur-sm h-full max-h-full border-r border-white/20  ${
            !isOpen && 'w-20'
          } items-center overflow-auto z-20 min-h-screen border-2`}
        >
          <Link href="/home" prefetch={false}>
            <div className="mt-4 cursor-pointer h-[40px]">
              <LilbitLogoSecond className="w-10 h-10" />
            </div>
          </Link>
          <div className="py-10 space-y-2 border-b border-white/10 w-full flex flex-col items-center ">
            {IconButtons.map((Iconbutton, index) => {
              return (
                <IconButton
                  key={index}
                  isOpen={isOpen}
                  href={Iconbutton.href}
                  title={intl.formatMessage({
                    id: Iconbutton.title,
                    defaultMessage: Iconbutton.defaultTitle,
                  })}
                  icon={Iconbutton.icon}
                />
              )
            })}
          </div>
          <RandomLeagues
            {...rest}
            isOpen={isOpen}
            appendedData={rest}
            sport={sortedSports}
          />
          <div className="pt-10 w-full flex flex-col h-full flex-1 ">
            <div className="flex flex-col w-full relative items-center">
              {showIcons &&
                sortedSports?.length > 0 &&
                sortedSports?.map((sport, index) => (
                  <SecondaryIconButton
                    key={index}
                    sport={sport}
                    appendedData={rest}
                    isOpen={isOpen}
                    onClick={() => rest?.setActiveSport(sport?.name)}
                  />
                ))}
              {showIcons && (
                <>
                  <SecondaryIconButton
                    sport={{
                      name: 'motorsports',
                      logo: F1,
                    }}
                    appendedData={rest}
                    isOpen={isOpen}
                    onClick={() => router.push('/sports/futures/motorsports')}
                  />
                  <SecondaryIconButton
                    sport={{
                      name: 'golf',
                      logo: Golf,
                    }}
                    appendedData={rest}
                    isOpen={isOpen}
                    onClick={() => router.push('/sports/futures/golf')}
                  />
                </>
              )}
            </div>
            <div
              onClick={handleSidebar}
              className={`text-white hover:bg-[#000000]/5 hover:backdrop-blur-sm cursor-pointer flex items-center rounded-xl mx-auto mt-4 p-3`}
            >
              {isOpen && (
                <p className="mr-4">
                  <FormattedMessage
                    id="navLeftBar.collapse"
                    defaultMessage="Collapse"
                  />
                </p>
              )}
              {isOpen ? (
                <ChevronDoubleLeftIcon className="w-6 h-6" />
              ) : (
                <ChevronDoubleRightIcon className="w-6 h-6" />
              )}
            </div>
            <div className="h-4">&nbsp;</div>
          </div>
        </div>
      )}
    </Transition>
  )
}

Sportsidebar.propTypes = {
  sidebarOpen: PropTypes.bool,
  // setSidebarOpen: PropTypes.func.isRequired,
  footballIcon: PropTypes.bool.isRequired,
}

export default memo(Sportsidebar)

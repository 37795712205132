import dynamic from 'next/dynamic'
import { XIcon } from '@heroicons/react/solid'

const Model = dynamic(() => import('@/components/model'), {
  ssr: false,
})

export default function RemoveTextModal({
  onSend,
  isOpen,
  message,
  setIsOpen,
}) {
  const onClose = () =>
    setIsOpen((state) => ({ ...state, removeTextModal: false, message: null }))

  const onHandle = () => {
    onSend({
      message,
    })
  }

  return (
    <Model
      onClose={onClose}
      isOpen={isOpen}
      modelBG="bg-black/90"
      className="px-6 overflow-auto max-w-xl mt-[-5vh]"
    >
      <div className="w-full p-4 flex flex-col justify-start">
        <h2 className="text-2xl mt-0 text-center ">Warning!</h2>
      </div>
      <div
        className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
        onClick={onClose}
      >
        <XIcon className="w-5 h-5 text-gray-300" />
      </div>

      <p className="mt-2 text-left">
        Are you sure you want to delete the message?
      </p>
      <div className="flex justify-end items-center mt-2">
        <button
          onClick={onHandle}
          className="flex bg-cs-green px-5 py-1 rounded-md"
        >
          Yes
        </button>
      </div>
    </Model>
  )
}

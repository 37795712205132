import dynamic from 'next/dynamic'
import { XIcon } from '@heroicons/react/solid'

const Model = dynamic(() => import('@/components/model'), {
  ssr: false,
})

export default function WarningModal({ onSend, isOpen, message, setIsOpen }) {
  const onClose = () =>
    setIsOpen((state) => ({ ...state, warningModal: false, message: null }))

  const onHandle = () => {
    onSend({
      message,
    })
  }

  const generateDate = (date) => {
    if (date) {
      const startDate = new Date(0)
      const resultDate = new Date(
        startDate.getTime() + date.seconds * 1000 + date.nanoseconds / 1000000,
      )
      return resultDate.toUTCString()
    }
  }
  return (
    <Model
      onClose={onClose}
      isOpen={isOpen}
      modelBG="bg-black/90"
      className="px-6 overflow-auto max-w-xl mt-[-5vh]"
    >
      <div className="w-full p-4 flex flex-col justify-start">
        <h2 className="text-2xl mt-0 text-center ">Warning!</h2>
      </div>
      <div
        className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
        onClick={onClose}
      >
        <XIcon className="w-5 h-5 text-gray-300" />
      </div>
      <p className="mt-2 text-left">
        Reason: <span className="text-red-400">{message?.reason}</span>
      </p>
      <p className="mt-2 text-left">
        Message: &quot;<span className="text-red-400">{message?.text}</span>
        &quot;
      </p>
      <p className="mt-5 text-left">
        You received a warning on{' '}
        <span className="underline underline-offset-2 text-red-400 decoration-stone-300">
          {generateDate(message?.warningDate)}
        </span>{' '}
        for the following reason.Please be more sensitive about the rules.
      </p>
      <p className="mt-2 text-left text-gray-400 text-sm">
        I take this warning into consideration and assure you that I will be
        more careful.
      </p>
      <div className="flex justify-end items-center mt-2">
        <button
          onClick={onHandle}
          className="flex bg-cs-green px-5 py-1 rounded-md"
        >
          Confirmation
        </button>
      </div>
    </Model>
  )
}

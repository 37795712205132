import sidebarSelector from '@/redux/selectors/siderbar'
import { toggleChatBar } from '@/redux/slices/sidebar'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

export default function useSidebar() {
  const { isOpen, sports, chat, leagues, visitedLeagues } =
    useSelector(sidebarSelector)

  const router = useRouter()
  const dispatch = useDispatch()
  const setActiveSportHandler = (sport, league = null) => {
    if (sport === 'soccer' && !league) {
      router.push(`/sports/soccer?league=popular-soccer`)
      return
    } else {
      router.push(
        `/sports/${sport}?league=${
          league ? league : leagues[sport?.toLowerCase()][0].name
        }`,
      )
    }
  }

  return {
    isOpen,
    chat,
    sports,
    leagues,
    visitedLeagues,
    setChat: () => dispatch(toggleChatBar()),
    setActiveSport: setActiveSportHandler,
  }
}
